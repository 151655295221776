import { Link, graphql } from "gatsby"
import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SplitTile from "../../components/splitTile"

/** @jsx jsx */
import {
  jsx,
  Button,
  Heading,
  Styled,
  Text,
  Box,
  Link as ThemeUILink,
} from "theme-ui"

import * as content from "../../content/handbuch/content.js"
import handbuchCover from "../../images/books/handbuch.jpg"

console.log("starcraft", handbuchCover)

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      image={handbuchCover}
      location={location}
      title="Handbuch für Autorinnen und Autoren"
      description="Handbuch für Autorinnen und Autoren. Das Standardwerk für alle, die schreiben. DIE Investition in Ihre
        Zukunft!"
    />
    <SplitTile
      image={data.cover.childImageSharp.fluid}
      imageInfo="Cover von »Handbuch für Autorinnen und Autoren«"
    >
      <Heading as="h1" sx={{ fontSize: 4, fontWeight: 900 }}>
        Handbuch für Autorinnen und Autoren
      </Heading>
      <Text>
        Das Standardwerk für alle, die schreiben. DIE Investition in Ihre
        Zukunft!
      </Text>
      <p sx={{ fontSize: 4, fontWeight: "bold" }}>54,90 EUR</p>
      <Text>
        <strong>Achtung:</strong> Print-Ausgabe ist vergriffen.
        <br />
        <font color="red">
          Jetzt als E-Book/PDF für <bold>9,99 EUR</bold> in diversen
          Online-Shops.
        </font>
        <br />
        <br />
      </Text>
    </SplitTile>
    <Box>
      <Text variant="subheading">8. Auflage: Februar 2015</Text>
      <Text variant="default">
        Das »Handbuch für Autorinnen und Autoren«: Bestellen Sie jetzt die 8.
        Ausgabe (2015) als E-Book zum Preis von 9,99 Euro.
      </Text>
      <Text variant="default">
        Mit einem ausführlichen Kommentar zum neuen Normvertrag für den
        Abschluss von Verlagsverträgen.
      </Text>
      <Text variant="default">
        Das literaturcafe.de schreibt in{" "}
        <Styled.a
          href="http://www.literaturcafe.de/handbuch-fuer-autorinnen-und-autoren-aus-dem-uschtrin-verlag-8-auflage-erschienen/"
          target="_blank"
          rel="noopener noreferrer"
        >
          seiner Rezension
        </Styled.a>{" "}
        <FaExternalLinkAlt sx={{ fontSize: 14 }} />: »Der Gral des
        Autorenwissens. [...] Ohne Zweifel: Das Handbuch muss jede Autorin und
        jeder Autor haben. Ohne geht es nicht.«
      </Text>
      <Text variant="default">
        Zum Kennenlernen finden Sie unten drei Leseproben, das
        Inhaltsverzeichnis, Vorwort und Register zum Download.
      </Text>
      <Text variant="default">
        Das{" "}
        <Styled.a
          href="https://youtu.be/dcldYUxZaXc"
          target="_blank"
          rel="noopener noreferrer"
        >
          Video »DRUCKFRISCH«
        </Styled.a>{" "}
        <FaExternalLinkAlt sx={{ fontSize: 14 }} /> zeigt den letzten Schritt
        der Fertigstellung in der Druckerei: Wie der "nackte" Buchblock seinen
        Buchdeckel erhält. Eine spannende Geschichte!
      </Text>
      <Text variant="default" sx={{ fontWeight: "bold" }}>
        Eine 9. Ausgabe ist derzeit nicht geplant.
      </Text>
      <Text variant="subheading">Rezensionen</Text>
      {content.reviewLinks.map((link, index) => (
        <Text variant="default">
          {index + 1}.{" "}
          <Styled.a
            href={link.target}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </Styled.a>{" "}
          <FaExternalLinkAlt sx={{ fontSize: 14 }} />
        </Text>
      ))}
      <Text variant="subheading">Dateien zum Download</Text>
      <Text>
        <ul>
          {content.downloadFiles.map(file => (
            <Styled.li>
              <Styled.a href={file.varName}>{file.text}</Styled.a>
            </Styled.li>
          ))}
        </ul>
      </Text>
    </Box>
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org",
        "@type": "Book",
        "@id": "https://shop.autorenwelt.de/products/handbuch-fur-autorinnen-und-autoren-informationen-und-adressen-aus-dem-deutschen-literaturbetrieb-und-der-medienbranche-von-sandra-uschtrin-heribert-hinrichs-hrsg#creative-work",
        "url": "https://www.uschtrin.de/buecher/uschtrin-handbuch-fuer-autorinnen-und-autoren",
        "sameAs": "https://shop.autorenwelt.de/products/handbuch-fur-autorinnen-und-autoren-informationen-und-adressen-aus-dem-deutschen-literaturbetrieb-und-der-medienbranche-von-sandra-uschtrin-heribert-hinrichs-hrsg?variant=51724256582",
        "name": "Handbuch für Autorinnen und Autoren: Informationen und Adressen aus dem deutschen Literaturbetrieb und der Medienbranche",
        "abstract": "Seit über 30 Jahren DAS Standardwerk für Autorinnen und Autoren",
        "isbn": "9783932522161",
        "bookEdition": "8., völlig überarbeitete und erweiterte Ausgabe",
        "publisher": {
          "@type": "Organization",
          "@id": "https://www.uschtrin.de#organisation",
          "name": "Uschtrin Verlag"
        },
        "editor": {
          "@type": "Person",
          "givenName": "Heribert",
          "familyName":"Uschtrin",
          "sameAs": "https://www.autorenwelt.de/person/heribert-uschtrin"
        },
        "bookFormat": "Halbleinen",
        "image": "https://www.uschtrin.de${handbuchCover}",
        "numberOfPages": "703"
      }`}
    </script>
  </Layout>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "books/handbuch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
