import anzeigenkunden from "../../content/handbuch/anzeigenkunden_hb8.pdf"
import cover from "../../images/books/handbuch.jpg"
import inhaltsverzeichnis from "../../content/handbuch/inhaltsverzeichnis_hb8.pdf"
import leseprobe1 from "../../content/handbuch/leseprobe1_kommentar-normvertrag_kap13.pdf"
import leseprobe2 from "../../content/handbuch/leseprobe2_uschtrin_kap01.pdf"
import leseprobe3 from "../../content/handbuch/leseprobe3_verlage-und-genres_kap10.pdf"
import pressemitteilung from "../../content/handbuch/pressemitteilung_hb8.pdf"
import register from "../../content/handbuch/register_hb8.pdf"
import vorwort from "../../content/handbuch/vorwort_hb8.pdf"

export const reviewLinks = [
  {
    target:
      "https://literaturcafe.de/handbuch-fuer-autorinnen-und-autoren-aus-dem-uschtrin-verlag-8-auflage-erschienen/",
    text: "Wolfgang Tischer, literaturcafe.de",
  },
  {
    target: "https://www.ruprechtfrieling.de/uschtrin/",
    text: "Ruprecht Frieling, rupprechtfrieling.de",
  },
  {
    target:
      "https://www.selfpublisherbibel.de/autorentipp-beim-roman-ist-lange-nicht-schluss-achte-auflage-des-handbuchs-fuer-autorinnen-und-autoren/",
    text: "Matthias Matting, selfpublisherbibel.de",
  },
  {
    target:
      "https://buchrezicenter.filmbesprechungen.de/genres/bildung/handbuch-fur-autorinnen-und-autoren-8-auflage/",
    text: "Werner Karl, buchrezicenter.de",
  },
  {
    target: "http://hoerspielkritik.de/wie-geht-hoerspiel/",
    text: "Jochen Meißner, hoerspielkritik.de",
  },
  {
    target:
      "http://blog.buecherfrauen.de/ueber-das-schreiben-und-publizieren-wissen-in-bestform/",
    text: "Marion Voigt, blog-buecherfrauen.de",
  },
  {
    target:
      "http://juttareichelt.com/2015/10/15/handbuch-fuer-autoren-informativer-als-ein-messebesuch/",
    text: "Jutta Reichelt, juttareichelt.de",
  },
  {
    target: "http://literaturkritik.de/public/rezension.php?rez_id=23163",
    text: "Günther Fetzer, literaturkritik.de",
  },
]

export const downloadFiles = [
  { varName: inhaltsverzeichnis, text: "inhaltsverzeichnis_hb8.pdf" },
  { varName: vorwort, text: "vorwort_hb8.pdf" },
  { varName: register, text: "register_hb8.pdf" },
  { varName: leseprobe1, text: "leseprobe1_kommentar-normvertrag_kap13.pdf" },
  { varName: leseprobe2, text: "leseprobe2_uschtrin_kap01.pdf" },
  { varName: leseprobe3, text: "leseprobe3_verlage-und-genres_kap10.pdf" },
  { varName: pressemitteilung, text: "pressemitteilung_hb8.pdf" },
  { varName: cover, text: "handbuch.jpg" },
  { varName: anzeigenkunden, text: "anzeigenkunden_hb8.pdf" },
]
